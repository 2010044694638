import React from 'react'
import '../../components/css/promo.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import CashbackToBusiness from 'images/cashback-to-business.png'
import { Image } from 'react-bootstrap'
import Helmet from 'react-helmet'
import HostName from '../../data/static/HostNames'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/promos/CashbackToBusiness`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'CASHBACK TO BUSINESS (February 1-28, 2022)',
  },
  {
    property: 'og:description',
    content:
      'Get P100 cashback when you sign up with a minimum top-up amount of P2,500 and transact an accumulated e-load transaction amount of P2,500',
  },
  {
    property: 'og:image',
    content: CashbackToBusiness,
  },
]

const Promo4 = () => (
  <div>
    <Helmet title="Promos | Cashback to Business" meta={SITE_META}>
      <html lang="en" />
    </Helmet>
    <Header />
    <div className="promo-page">
      <h1 align="center" className="font-weight-bold">
        DIGIPAY CASHBACK TO BUSINESS
      </h1>
      <Image
        src={CashbackToBusiness}
        width="100%"
        align="center"
        className="promo-image"
      />
      <h4>MECHANICS</h4>
      <div>
        <ul>
          <li>
            Get P100 cashback when you sign up with a minimum top-up amount of
            P2,500 and transact an accumulated e-load transaction amount of
            P2,500 within 30 days upon signing up
            <ul>
              <li>
                Example:
                <ul>
                  <li>
                    The agent will be eligible to receive P100 cashback if
                    he/she signed up on February 5, 2022 with an initial top-up
                    amount of P2,500 and transacted an accumulated e-load amount
                    of P2,500 within 30 days upon signing up (March 7, 2022)
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <h4>HOW TO CLAIM THE CASHBACK</h4>
      <div>
        <ul>
          <li>
            Digipay Team will credit the cashback within five (5) days from the
            end of the month after the agent’s completion of the P2,500 e-load
            transaction volume
          </li>
          <ul>
            <li>Examples:</li>
            <ul>
              <li>
                If the agent completed the accumulated P2,500 e-load transaction
                volume on February 27, 2022, he/she will receive P100 cashback
                within five (5) days from February 28, 2022
              </li>
              <li>
                If the agent completed the accumulated P2,500 e-load transaction
                volume on March 3, 2022, he/she will receive P100 cashback
                within five (5) days from March 31, 2022
              </li>
            </ul>
          </ul>
        </ul>
      </div>
    </div>
    <Footer />
  </div>
)
export default Promo4
